import React from 'react';
import {
    f7,
    Page,
    Navbar,
    Preloader,
    Button,
    Icon,
    Popover,
    List,
    ListItem,
    Card,
    CardContent,
    Block,
    Popup,
    NavRight,
    BlockTitle,
    Chip,
    NavTitle,
    NavLeft,
    Toggle,
    Badge
} from 'framework7-react';

import {API_URL} from '../../../api';

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            popover_data: null,
            popover_data_index: null,
            row_error_popup: false,
            row_inactive_arr: [],
            infinite_loading: false,
            infinite_preloader: true,
            group_index: 0,
            show_group_badget: false
        };
    }

    componentDidMount(){
        this.loadXhr();
    }

    loadXhr(){
        const surl = this.props.f7route.params.surl;
        const eid = this.props.f7route.params.id;
        const tid = this.props.f7route.params.tid;
        fetch(API_URL+'/event/participants', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              eid: eid,
              tid: tid,
              surl: surl
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            if(data.err_code === 0){
                 //format group:
                 let participants_arr = [];
                 if(data.participants){
                     data.participants.forEach((e)=>{
                         let group = false;
                         if(e.group){
                             let group_index = this.state.group_index;
                             if(e.group.parent){
                                 this.setState({group_index:(group_index + 1)});
                                 group_index = group_index + 1;
                             }
                             let parent_id;
                             if(e.group.parent){
                                 parent_id = e.rid;
                             }else{
                                 parent_id = e.group.parent_id;
                             }
                             group = {
                                 index : group_index,
                                 parent: e.group.parent,
                                 parent_id: parent_id
                             };
                         }
                         participants_arr.push({
                             rid: e.rid,
                             checked_in: e.checked_in,
                             data: e.data,
                             email: e.email,
                             name: e.name,
                             tel: e.tel,
                             tel_prefix: e.tel_prefix,
                             group: group
                         });
                     });
                 }
                 this.setState({
                     loading: false,
                     error: false,
                     data: data,
                     event_data: data.event_data,
                     participants: participants_arr
                 });
                 if(data.participants.length < 20 || data.participants === false){
                     this.setState({
                         infinite_preloader:false
                     });
                 }
            }else{
                this.setState({
                    loading: false,
                    infinite_preloader:false,
                    error: true,
                    error_mgs: data.mgs,
                });
            }
        })
        .catch(err => {
        this.setState({
            loading:false,
            infinite_preloader:false,
            error:true,
            error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
        })

    }

    loadXhrInfinite(){
        if(this.state.loading === false && this.state.infinite_loading === false){

            let last_id = this.state.participants[this.state.participants.length - 1].rid;

            this.setState({infinite_loading:true});

            const surl = this.props.f7route.params.surl;
            const eid = this.props.f7route.params.id;
            const tid = this.props.f7route.params.tid;
            fetch(API_URL+'/event/participants', {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                eid: eid,
                tid: tid,
                start: last_id,
                surl: surl
                })
            })
            .then(response => {
            return response.json()
            })
            .then(data => {
                if(data.err_code === 0){
                    if(data.participants){
                        let new_array = this.state.participants;
                        //format group:
                        let participants_arr = [];
                        data.participants.forEach((e)=>{
                            let group = false;
                            if(e.group){
                                let group_index = this.state.group_index;
                                if(e.group.parent){
                                    this.setState({group_index:(group_index + 1)});
                                    group_index = group_index + 1;
                                }
                                let parent_id;
                                if(e.group.parent){
                                    parent_id = e.rid;
                                }else{
                                    parent_id = e.group.parent_id;
                                }
                                group = {
                                    index : group_index,
                                    parent: e.group.parent,
                                    parent_id: parent_id
                                };
                            }
                            participants_arr.push({
                                rid: e.rid,
                                checked_in: e.checked_in,
                                data: e.data,
                                email: e.email,
                                name: e.name,
                                tel: e.tel,
                                tel_prefix: e.tel_prefix,
                                group: group
                            });
                        });

                        this.setState({
                            infinite_loading:false,
                            participants: new_array.concat(participants_arr)
                        });
                        if(data.participants.length < 20){
                            this.setState({
                                infinite_preloader:false
                            });
                        }
                    }else{
                        this.setState({
                            infinite_preloader:false
                        });
                    }
                }else{
                    this.setState({
                        infinite_loading:false
                    });
                }
            })
            .catch(err => {
            this.setState({
                loading:false,
                error:true,
                error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
            });
            })
            
        }
    }

    renderDataRow = (t,e) => {

        if(e.data[0].c_id === 0){
            return false;
        }

        let active_arr = [];
        let inactive_arr = [];

        e.data.forEach( el => {
            let cid = el.c_id;
            let d = t.find(x => x.id === cid);
            if(d){
                if(d.active){
                    active_arr.push({col:d,d:el});
                }else{
                    inactive_arr.push({col:d,d:el});
                }
            }else{
                return false;
            }
        });

        let active_col = [];
        this.state.data.fields.forEach( el => {
            if(el.active && el.type !== 5 && el.type !== 1 && el.type !== 6 ){
                active_col.push(el.id);
            }
        });

        let render_arr = [];
        active_col.forEach( id => {
            render_arr.push(active_arr.find(x => x.col.id === id));

        });

        return <>
        {render_arr.map(v => (
            <td className="label-cell">
                {v && this.renderDataCol(v)}
            </td>
        ))}
        {inactive_arr.length > 0 ?
        <th style={{padding:'10px',maxWidth:'34px',width:'26px',paddingRight:'36px'}}>
             <Button onClick={()=>this.setState({row_error_popup:true,row_inactive_arr:inactive_arr})} iconSize={20} small style={{width:'36px',marginLeft:'-12px',textOverflow:'clip',backgroundColor:"#FAD201"}} iconMaterial="warning" fill/>
        </th>
        :
        <th></th>
        }
        </>;

    } 

    renderDataCol(c) {
        
        if(c.d.type === 3){
            let arr = c.col.data;
            if(Array.isArray(arr)){
                return arr[c.d.data];
            }else{
                return <i>Svar mangler</i>;
            }
        }else if(c.d.type === 4){

            let arr = c.col.data;
            if(Array.isArray(arr)){
            
                let res = '';
                
                JSON.parse(c.d.data).forEach((e,i) => {
                    if(e === 1){
                        res = res + `${arr[i]}, `;
                    }
                });
                return res.substring(0, res.length-2);;
            
            }else{
                return <i>Svar mangler</i>;
            }
        }
        return c.d.data;

    }

    renderOptfieldCol(c) {
        if( (c.type !== 1 && c.type !== 5 && c.type !== 6) && c.active === 1 ){
            return <th key={c.id} className="label-cell">{c.title}</th>;
        }
    }

    renderColBtn = (n, i, waiting) => {
        return <th style={{padding:'10px',maxWidth:'34px'}}>
            <Button onClick={(e)=>{e.stopPropagation();this.setState({popover_data: n, popover_data_index: i, popover_is_waiting:waiting});}} popoverOpen=".popover-menu" iconSize={17} small style={{margin:0,textOverflow:'clip'}} iconMaterial="more_vert" fill/>
        </th>;
    }

    renderColUsed(n) {
        return (<th style={{padding:'10px',maxWidth:'34px'}}>
            <Icon color={n === 0 ? 'gray' : 'green'} size="26px" material={n === 0 ? 'check_box_outline_blank' : 'check_box'}></Icon>
        </th>);
    }

   
    updateParticipant() {
        const self = this;
        f7.dialog.preloader('Vent litt');
        const surl = this.props.f7route.params.surl;
        const eid = this.props.f7route.params.id;
        let value = 0;
        if(this.state.popover_data.checked_in === 0){
            value = 1;
        }else{
            value = 0;
        }

        fetch(API_URL+'/event/participant/update', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              eid: eid,
              rid: this.state.popover_data.rid,
              checked_in: value,
              surl: surl
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            f7.dialog.close();
            self.update_participant_render(value);
        })
        .catch(err => {
            f7.dialog.close();
            f7.dialog.alert('Deltakeren status ble ikke endret...', 'En ukjent feil har oppstått');
        })
    }

    update_participant_render = (v) => {
        let newState = Object.assign({}, this.state);
        newState.participants[this.state.popover_data_index].checked_in = v;
        this.setState(newState);
    }

    remove_participant_render = () => {
        let newState = Object.assign({}, this.state);
        newState.participants.splice(this.state.popover_data_index, 1);
        this.setState(newState);
    }

    render_group = (group) => {
        const eid = this.props.f7route.params.id;
        const surl = this.props.f7route.params.surl;
        let palette = ['#D32F2F', '#7B1FA2', '#303F9F', '#0288D1', '#00796B', '#689F38', '#FBC02D'];
        let group_color = group.index;  
        if(group.index > 7){
            group_color = group.index - 7;  
        }
        return this.state.show_group_badget&& ( group?<th style={{padding:'10px',maxWidth:'66px'}}><a onClick={(e)=>{e.stopPropagation();f7.views.main.router.navigate(`/${surl}/${eid}/group/${group.parent_id}`)}}><Badge style={{backgroundColor:palette[group_color-1],width:'66px'}}>Gruppe {group.index}</Badge></a></th>:<th style={{padding:'10px',maxWidth:'34px'}}></th>);
    }

    render() {
    const eid = this.props.f7route.params.id;
    const surl = this.props.f7route.params.surl;
    return <Page infinite onInfinite={()=>this.loadXhrInfinite()} infinitePreloader={this.state.infinite_preloader && !this.state.loading}>
        <Navbar>
            <NavLeft>
              <Button back round large className="NavbarIconView" iconMaterial="arrow_back" className="NavbarIconView" style={{marginLeft:"5px"}}/>
            </NavLeft>
            <NavTitle>
                {this.state.loading === false && this.state.error === false && 
                <>{this.state.data.title ? <>{this.state.data.title}{this.state.data.active === 0 &&<Chip text="Inaktiv" color="red" style={{height:'26px',marginLeft:'8px',marginBottom:'4px'}}/>}</>:`Alle deltakere`}</>}
            </NavTitle>
            <NavRight>
            {(!this.state.loading && !this.state.error && this.state.data.options.allow_group === 1) &&
                <>
                <span style={{fontWeight:'normal',fontSize:'14px'}}>
                    Vis grupper:
                <Toggle style={{marginLeft:'6px'}} onToggleChange={(e)=>this.setState({show_group_badget:!e})} checked={this.state.show_group_badget}></Toggle>
                </span>
                <span style={{opacity:.4,marginLeft:'12px',marginRight:'16px'}}>
                    |
                </span>
                </>
                }
                <Button fill raised href={`/${surl}/${eid}/search`}>
                    <Icon size="22px" material="search"/><span style={{marginLeft:'6px'}} className={'hideMobileScreen'}>Søk</span>
                </Button>
            </NavRight>
        </Navbar>
        {this.state.loading
        ? (
          <div className="text-align-center" style={{margin:'26px',marginTop:'24px'}}>
            <Preloader></Preloader>
          </div>
        ) : (
            this.state.error
            ? (
                <Card outline style={{color:'#fff', backgroundColor:'#e74c3c',maxWidth:'440px',marginLeft:'auto',marginRight:'auto'}}>
                    <CardContent>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>{this.state.error_mgs}</span>
                    </CardContent>
                </Card>
            ) : (
            <>
            {this.state.participants.length > 0 ?
                <>
                <div className={'data-table'}>
                  <table>
                      <thead>
                        <tr>
                            <th style={{padding:0,width:'40px'}}></th>
                            <th style={{padding:0}}></th>
                            {this.state.show_group_badget && <th style={{padding:0}}></th>}
                            {this.state.data.fields.find(x => x.type === 6) &&
                                <th className="label-cell">Navn</th>
                            }
                            {this.state.data.fields.find(x => x.type === 1) &&
                            <th className="label-cell">Telefon</th>
                            }
                            {this.state.data.fields.find(x => x.type === 5) &&
                            <th className="label-cell">E-post</th>
                            }
                            {this.state.data.fields.map( n => (
                                this.renderOptfieldCol(n)
                            ))}
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.participants.map( (n, i) => (
                          ( this.state.data.event_type === 1 && this.state.data.options.max_participants !== 0 ) ?
                            <>
                            {i <= (this.state.data.qty - 1) &&
                            <tr key={n} style={{cursor:'pointer'}} onClick={()=>{f7.views.main.router.navigate(`/${surl}/${eid}/participant/${n.rid}`)}}>
                                {this.renderColBtn(n, i, false)}
                                {this.renderColUsed(n.checked_in)}
                                {this.render_group(n.group)}
                                {n.name &&
                                <td className="label-cell">
                                    {n.name}
                                </td>
                                }
                                {n.tel > 0 &&
                                <td className="label-cell">
                                {n.group && n.group.parent === false ? <span style={{opacity:.5}}><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</span>:<><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</>}
                                </td>
                                }
                                {n.email &&
                                <td className="label-cell">
                                    {n.group && n.group.parent === false ? <span style={{opacity:.5}}>{n.email}</span>:n.email}
                                </td>
                                }
                                {this.renderDataRow(this.state.data.fields, n)}
                            </tr>
                            }
                            {i === this.state.data.qty && this.state.data.qty !== 0 &&
                            <>
                            <div style={{width:'10px',marginLeft:'12px',marginTop:'8px',marginBottom:'2px',fontWeight:600}}>Venteliste</div>
                            <tr key={n} onClick={()=>{f7.views.main.router.navigate(`/${surl}/${eid}/participant/${n.rid}`)}}>
                                {this.renderColBtn(n, i, true)}
                                <th style={{padding:'10px',maxWidth:'34px'}}/>
                                {this.render_group(n.group)}
                                {n.name &&
                                <td className="label-cell">
                                    {n.name}
                                </td>
                                }
                                {n.tel > 0 &&
                                <td className="label-cell">
                                {n.group && n.group.parent === false ? <span style={{opacity:.5}}><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</span>:<><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</>}
                                </td>
                                }
                                {n.email &&
                                <td className="label-cell">
                                    {n.group && n.group.parent === false ? <span style={{opacity:.5}}>{n.email}</span>:n.email}
                                </td>
                                }
                                {this.renderDataRow(this.state.data.fields, n)}
                            </tr>
                            </>
                            }
                            {i > this.state.data.qty &&
                            <tr key={n} onClick={()=>{f7.views.main.router.navigate(`/${surl}/${eid}/participant/${n.rid}`)}}>
                                {this.renderColBtn(n, i, true)}
                                <th style={{padding:'10px',maxWidth:'34px'}}/>
                                {this.render_group(n.group)}
                                {n.name &&
                                <td className="label-cell">
                                    {n.name}
                                </td>
                                }
                                {n.tel > 0 &&
                                <td className="label-cell">
                                {n.group && n.group.parent === false ? <span style={{opacity:.5}}><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</span>:<><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</>}
                                </td>
                                }
                                {n.email &&
                                <td className="label-cell">
                                    {n.group && n.group.parent === false ? <span style={{opacity:.5}}>{n.email}</span>:n.email}
                                </td>
                                }
                                {this.renderDataRow(this.state.data.fields, n)}
                            </tr>
                            }
                            {this.state.data.qty === 0 &&
                            <tr key={n} onClick={()=>{f7.views.main.router.navigate(`/${surl}/${eid}/participant/${n.rid}`)}}>
                                {this.renderColBtn(n, i, true)}
                                <th style={{padding:'10px',maxWidth:'34px'}}/>
                                {this.render_group(n.group)}
                                {n.name &&
                                <td className="label-cell">
                                    {n.name}
                                </td>
                                }
                                {n.tel > 0 &&
                                <td className="label-cell">
                                {n.group && n.group.parent === false ? <span style={{opacity:.5}}><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</span>:<><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</>}
                                </td>
                                }
                                {n.email &&
                                <td className="label-cell">
                                    {n.group && n.group.parent === false ? <span style={{opacity:.5}}>{n.email}</span>:n.email}
                                </td>
                                }
                                {this.renderDataRow(this.state.data.fields, n)}
                            </tr>
                            }
                            </>
                          :
                            <tr key={n} onClick={()=>{f7.views.main.router.navigate(`/${surl}/${eid}/participant/${n.rid}`)}}>
                                {this.renderColBtn(n, i, false)}
                                {this.renderColUsed(n.checked_in)}
                                {this.render_group(n.group)}
                                {n.name &&
                                <td className="label-cell">
                                    {n.name}
                                </td>
                                }
                                {n.tel > 0 &&
                                <td className="label-cell">
                                {n.group && n.group.parent === false ? <span style={{opacity:.5}}><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</span>:<><span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}</>}
                                </td>
                                }
                                {n.email &&
                                <td className="label-cell">
                                    {n.group && n.group.parent === false ? <span style={{opacity:.5}}>{n.email}</span>:n.email}
                                </td>
                                }
                                {this.renderDataRow(this.state.data.fields, n)}
                            </tr>
                      ))}
                      </tbody>
                  </table>
                </div>
                </>
                :
                <Block>
                <Card outline style={{marginLeft:'auto', marginRight:'auto', maxWidth:'420px', backgroundColor:'#F8F8F8'}}>
                    <CardContent className="text-align-center">
                    <p>
                        Ingen er påmeldt med denne påmeldingstypen.
                    </p>
                    </CardContent>
                </Card>
                </Block>
                }
        <Popover className="popover-menu">
            {this.state.popover_data &&
            <>
                <List mediaList>
                    <ListItem disabled={this.state.popover_is_waiting} onClick={()=>this.updateParticipant()} link="#" popoverClose title={this.state.popover_data.checked_in === 1 ? 'Sett til ubrukt' : 'Sett til brukt'} noChevron={true}>
                        <Icon slot="media" color={this.state.popover_data.checked_in === 1 ? 'gray' : 'green'} size="26px" material={this.state.popover_data.checked_in === 1 ? 'check_box_outline_blank' : 'check_box'}></Icon>
                    </ListItem>
                </List>
                <List style={{marginTop:0}} mediaList>
                    <ListItem link="#" popoverClose after={<span style={{color:"#ff3b30"}}>Avbryt</span>} noChevron={true}/>
                </List>
            </>
            }
        </Popover>
        <Popup closeByBackdropClick={false} opened={this.state.row_error_popup} onPopupClose={() => this.setState({row_error_popup : false} )}>
            <Page>
              <Navbar title="Opplysninger mangler">
                <NavRight>
                    <Button popupClose onClick={()=>this.setState({row_error_popup : false})} iconSize="28px" iconMaterial="close" round large style={{minWidth:'48px',marginRight:'8px'}}/>
                </NavRight>
              </Navbar>
              <Card outline style={{backgroundColor:"#FAD201"}}>
                <CardContent>
                    <p>
                    Det har blitt foretatt endringer på denne påmeldingstypen etter at deltakeren meldte seg på. Noen av opplysningene denne deltakeren oppga ved registrering er ikke lenger en del av påmeldingstypen.
                    </p>
                    <p>
                    Se hvilken opplysninger det gjelder under.
                    </p>
                </CardContent>
              </Card>
              {this.state.row_inactive_arr.map( (n) => (
              <>
              <BlockTitle>{n.col.title}</BlockTitle>
              <Block>
                  <p>
                     {this.renderDataCol(n)}
                  </p>
              </Block>
              </>
              ))}
            </Page>
          </Popup>
          </>))}
    </Page>
    }
};