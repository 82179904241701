import * as React from 'react';
import './pageBlock.css'

export default class PageBlock extends React.Component {

    constructColor() {
        if(this.props.color){
            return {backgroundColor:this.props.color}
        }
        return null;
    }

    constructWidth() {
        if(this.props.width){
            return {maxWidth:this.props.width}
        }
        return null;
    }

    render() {
      return <div className="pageBlockDiv" style={this.constructColor()}>
                <div className="center" style={this.constructWidth()}>
                    {this.props.children}
                </div>
            </div>;
    }
};