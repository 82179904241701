import React from 'react';
import ReactDOM from 'react-dom';
import Framework7 from 'framework7/bundle';
import Framework7React from 'framework7-react';
import App from './components/App.jsx';

// Framework7 styles
import 'framework7/framework7-bundle.css';
import './css/icons.css';
import './css/app.css';

// Init Framework7-React plugin
Framework7.use(Framework7React);

// Mount React App
ReactDOM.render(
  React.createElement(App),
  document.getElementById('app'),
);
