import React from 'react';
import { Page, Navbar, Card, CardContent, Icon } from 'framework7-react';

import MainNavbar from '../shared/navbar/navbar';

export default () => (
  <Page>
    <Navbar>
      <MainNavbar/>
    </Navbar>
    <Card outline style={{color:'#fff', backgroundColor:'#e74c3c',maxWidth:'440px',marginLeft:'auto',marginRight:'auto'}}>
                    <CardContent>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>Denne lenken er ikke gyldig.</span>
                    </CardContent>
    </Card>
  </Page>
);
