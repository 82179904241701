import * as React from 'react';
import {
    NavLeft,
    NavTitle,
    Button,
} from 'framework7-react';
import './navbar.css'

export default class NavbarBack extends React.Component {

  
    render() {
      return <>
            <NavLeft>
              <Button back round large className="NavbarIconView" iconMaterial="arrow_back" className="NavbarIconView" style={{marginLeft:"5px"}}/>
            </NavLeft>
            <NavTitle>
              {this.props.title}
            </NavTitle>
        </>;
    }
};