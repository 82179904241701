import React from 'react';
import {
    Page,
    Navbar,
    Searchbar,
    Icon,
    List,
    ListItem,
    Card,
    CardContent,
    BlockTitle,
} from 'framework7-react';

import NavbarBack from '../../shared/navbar/navbarBack';
import {API_URL} from '../../../api';
import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {

    state = {
        error: false,
        search_results: false
    };

    xhrSearchParticipant(q){
        const eid = this.props.f7route.params.id;
        const surl = this.props.f7route.params.surl;
    
        if(q.length > 2){
          this.setState({load_search:true});
          fetch(API_URL+'/event/participants/search', {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                eid: eid,
                q: q,
                surl: surl
              })
          })
          .then(response => {
          return response.json()
          })
          .then(data => {
            if(data.err_code === 0){
                this.setState({load_search:false,search_results:data.data,event_col:data.event_col,search_empty:true});
            }else{
                this.setState({load_search:false,search_results:false,search_empty:false});
            }
          })
          .catch(err => {
            this.setState({load_search:false,search_results:false,search_empty:false});
          })
    
        }else{
          this.setState({load_search:false,search_results:false,search_empty:false});
        }
    
    }

    search_array_format = () => {
        let event_type_col;
        let arr = Array();
        let s = Array();
        
        this.state.search_results.forEach(el => {
            if(event_type_col === el.event_type_col){
                s.push(el);
            }else{
                if(s.length > 0){
                    arr.push({event_type_col:event_type_col,data:s});
                }
                event_type_col = el.event_type_col;
                s = Array();
                s.push(el);
            }
        });

        if(s.length > 0){
            arr.push({event_type_col:event_type_col,data:s});
        }
        return arr;
    }

    render_ticket_type = (cid) => {
        let d = this.state.event_col.find(x => x.id === cid);
        return d.title;
    }

    render_search_results(){
        const surl = this.props.f7route.params.surl;
        const eid = this.props.f7route.params.id;
        if(this.state.event_col.length === 1){
            return <Card outline>
                <List mediaList>
                {this.state.search_results.map( (n) => (
                <ListItem link={`/${surl}/${eid}/participant/${n.t_id}`} title={n.name?n.name:`Deltaker - ${n.t_id.match(/.{1,2}/g).join("-").toUpperCase()}`} subtitle={<>{n.tel > 0 &&<><Icon material="phone" colorTheme size={12}></Icon> <span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}<br/></>}{n.email && <><Icon material="mail" colorTheme size={12}></Icon> {n.email}</>}</>} after={n.t_id.match(/.{1,2}/g).join("-").toUpperCase()}>
                    {n.checked_in === 1 ?
                    <Icon slot="media" material="check_box" colorTheme></Icon>
                    :
                    <Icon slot="media" material="check_box_outline_blank" colorTheme></Icon>
                    }
                </ListItem>
                ))}
                </List>
            </Card>;
        }else{
            let data = this.search_array_format();
            return <>
                {data.map( (n) => (
                    <>
                    <BlockTitle>{this.render_ticket_type(n.event_type_col)}</BlockTitle>
                    <Card outline>
                        <List mediaList>
                        {(n.data.map( (n) =>
                        <ListItem link={`/${surl}/${eid}/participant/${n.t_id}`} title={n.name?n.name:`Deltaker - ${n.t_id.match(/.{1,2}/g).join("-").toUpperCase()}`} subtitle={<>{n.tel > 0 &&<><Icon material="phone" colorTheme size={12}></Icon> <span style={{color:'#666'}}>(+{n.tel_prefix})</span> {n.tel}<br/></>}{n.email && <><Icon material="mail" colorTheme size={12}></Icon> {n.email}</>}</>} after={n.t_id.match(/.{1,2}/g).join("-").toUpperCase()}>
                            {n.checked_in === 1 ?
                            <Icon slot="media" material="check_box" colorTheme="green"></Icon>
                            :
                            <Icon slot="media" material="check_box_outline_blank" colorTheme="gray"></Icon>
                            }
                        </ListItem>
                        ))}
                        </List>
                    </Card>
                    </>
                ))}
            </>;
        }
      }


    render() {
    return <Page>
        <Navbar>
            <NavbarBack title={'Søk etter deltaker'}/>
        </Navbar>
        <PageBlock width="920px">
        {this.state.error
        ? (
            <Card outline style={{color:'#fff', backgroundColor:'#e74c3c',maxWidth:'440px',marginLeft:'auto',marginRight:'auto'}}>
                <CardContent>
                    <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                    <span style={{fontSize:'18px'}}>{this.state.error_mgs}</span>
                </CardContent>
            </Card>
        ) : (
        <>  
         <Searchbar
                    disableButtonText="Avbryt"
                    placeholder="Søk på ID, navn, telefon eller e-post."
                    clearButton={true}
                    customSearch
                    onSearchbarSearch={(e)=>this.xhrSearchParticipant(e.query)}
                    autocomplete={false}
            ></Searchbar>
            {this.state.search_results ?
                this.render_search_results()
            :
            this.state.search_empty &&
                <Card outline style={{backgroundColor:"#dfe4ea",marginBottom:'18px',zIndex:1}}>
                    <CardContent>
                        <p>
                        Ingen resultater
                        </p>
                    </CardContent>
                </Card>
            }
        </>
        )}
        </PageBlock>
    </Page>
    }
};