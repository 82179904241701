import * as React from 'react';
import {
    NavRight,
    NavTitle,
    Button,
} from 'framework7-react';
import './navbar.css'

export default class MainNavbar extends React.Component {
    render() {
      return <NavTitle>
              {this.props.title}
            </NavTitle>
    }
};