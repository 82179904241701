import React from 'react';
import {
    f7,
    Page,
    Navbar,
    Preloader,
    Icon,
    List,
    ListItem,
    Card,
    CardContent,
    BlockTitle,
    Row,
    Col,
} from 'framework7-react';

import NavbarBack from '../../shared/navbar/navbarBack';
import PageBlock from '../../shared/pageBlock/pageBlock';
import {API_URL} from '../../../api';

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            popover_data: null,
            popover_data_index: null,
            row_error_popup: false,
            row_inactive_arr: [],
            infinite_loading: false,
            infinite_preloader: true,
            edit_participant:false
        };
    }

    componentDidMount(){
        this.loadXhr();
    }

    loadXhr(){
        const eid = this.props.f7route.params.id;
        const rid = this.props.f7route.params.rid;
        const surl = this.props.f7route.params.surl;
        fetch(API_URL+'/event/participant', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              eid: eid,
              id: rid,
              surl: surl
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            f7.dialog.close();
            if(data.err_code === 0){
                this.setState({
                    loading: false,
                    error: false,
                    data: data
                });
            }else{
                this.setState({
                    loading:false,
                    error:true,
                    error_mgs: data.err_mgs
                });
            }
        })
        .catch(err => {
            this.setState({
                loading:false,
                error:true,
                error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
            });
        })

    }

    renderDataCol(c) {
        
        if(this.state.data.event_col.fields.find(x => x.id === c.c_id)){
            let title = this.state.data.event_col.fields.find(x => x.id === c.c_id).title;
            let c_data = this.state.data.event_col.fields.find(x => x.id === c.c_id).data;
            let data = c.data;
        
            if(c.type === 3){
                let arr = c_data;
                if(Array.isArray(arr)){
                data = arr[data];
                }
            }else if(c.type === 4){
        
                let arr = c_data;
                if(Array.isArray(arr)){
                
                    let res = '';
                    
                    JSON.parse(data).forEach((e,i) => {
                        if(e === 1){
                            res = res + `${arr[i]}, `;
                        }
                    });
                    data = res.substring(0, res.length-2);;
                
                }
            }
        
            return <>
            <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>{title}</BlockTitle>
            <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                <ListItem
                    title={data}
                />
            </List>
            </>;
        }
        return false;
    }
    
      render_participant_data = () => {
        return <Card outline>
            {this.state.data.signup_data.name &&
            <>
            <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>Navn</BlockTitle>
            <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                <ListItem
                    title={this.state.data.signup_data.name}
                />
            </List>
            </>
            }
            {this.state.data.signup_data.email &&
            <>
            <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>E-post</BlockTitle>
            <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                <ListItem
                    title={this.state.data.signup_data.email}
                />
            </List>
            </>
            }
            {this.state.data.signup_data.tel > 0 &&
            <>
            <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>Telefon</BlockTitle>
            <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                <ListItem
                    title={<><span style={{color:'#666'}}>(+{this.state.data.signup_data.tel_prefix})</span> {this.state.data.signup_data.tel}</>}
                />
            </List>
            </>
            }
            {this.state.data.signup_data.data && this.state.data.signup_data.data.map( (n) => (
                this.renderDataCol(n)
            ))}
        </Card>
      }


  getTimeLeft(UNIX_timestamp){
    
    UNIX_timestamp = parseInt(UNIX_timestamp);

    var timeNow = Math.floor((new Date().getTime())/1000);
    var d = timeNow - UNIX_timestamp;
    var d_days = Math.floor(d / (24*60*60));
    var d_hours = Math.floor(d / (60*60));
    var d_minutes = Math.floor(d / 60);
    var d_second = Math.floor(d);
    if (d_days >= 2) {
        return d_days + ' dager';
    }
    else if (d_days < 2 && d_days >= 1) {
        return d_days + ' dag';
    }
    else if (d_days < 1 && d_hours >= 2) {
        return d_hours + ' timer ' + (d_minutes-(60*d_hours)) + ' minutter';
    }
    else if (d_hours < 2 && d_hours >= 1) {
        return d_hours + ' time';
    }
    else if (d_hours < 1 && d_minutes >= 2) {
        return d_minutes + ' minutter';
    }
    else if (d_minutes < 2 && d_minutes >= 1) {
        return d_minutes + ' minutt';
    }
    else if (d_minutes < 1 && d_second >= 0) {
        return ' mindre enn 1 minutt';
    }
  }

  formateTimestamp(timestamp) {
    const date = new Date(timestamp*1000);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const month_arr = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
    return day + '. ' + month_arr[month] + '. ' + year + ', kl. ' + hours + ':' + minutes.substr(-2);
  }

  formateTimestampTime(timestamp) {
    const date = new Date(timestamp*1000);
    const hours = "0" + date.getHours();
    const minutes = "0" + date.getMinutes();
    return hours.substr(-2) + ':' + minutes.substr(-2);
  }

  formateTimestampDate(timestamp) {
    const date = new Date(timestamp*1000);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const month_arr = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
    return day + '. ' + month_arr[month] + '. ' + year;
  }

  renderQrText(t_id) {
    return t_id.match(/.{1,2}/g).join("-").toUpperCase();
  }

  format_number = (nr) => {
    return nr.toFixed(2).toLocaleString('en').replace(/,/g,' ');
  }


updateParticipant() {
    f7.dialog.preloader('Vent litt');
    const surl = this.props.f7route.params.surl;
    const eid = this.props.f7route.params.id;
    const rid = this.props.f7route.params.rid;
    let value = 0;
    if(this.state.data.checked_in === 0){
        value = 1;
    }else{
        value = 0;
    }

    fetch(API_URL+'/event/participant/update', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          eid: eid,
          rid: rid,
          checked_in: value,
          surl: surl
        })
    })
    .then(response => {
    return response.json()
    })
    .then(data => {
        f7.dialog.close();
        this.setState({ data: { ...this.state.data, checked_in: value, checked_in_time: Date.now()/1000} });
    })
    .catch(err => {
        f7.dialog.close();
        f7.dialog.alert('Deltakeren status ble ikke endret...', 'En ukjent feil har oppstått');
    })
}



  render_options = () => {
    return <>
        <BlockTitle style={{marginTop:"16px"}}>Alternativer for deltaker</BlockTitle>
        <Card outline style={{backgroundColor:"#dfe4ea"}}>
            <List mediaList>
                <ListItem link disabled={this.state.data.waiting} onClick={()=>this.updateParticipant()} link title={this.state.data.checked_in === 1 ? 'Sett til ubrukt' : 'Sett til brukt'}>
                    <Icon slot="media" color={this.state.data.checked_in === 1 ? 'gray' : 'green'} size="26px" material={this.state.data.checked_in === 1 ? 'check_box_outline_blank' : 'check_box'}></Icon>
                </ListItem>
            </List>
        </Card>
    </>;
  }

    render() {
        const eid = this.props.f7route.params.id;
        const surl = this.props.f7route.params.surl;
        return <Page>
            <Navbar>
                <NavbarBack title={!this.state.loading && !this.state.error && <>Deltaker - <i>{this.renderQrText(this.state.data.signup_data.t_id)}</i></>}/>
            </Navbar>
            {this.state.loading
            ? (
                <div className="text-align-center" style={{margin:'26px'}}>
                  <Preloader></Preloader>
                </div>
            ) : (
                <PageBlock width="920px">
                {this.state.error ? (
                <Card>
                    <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>{this.state.error_mgs}</span>
                    </CardContent>
                </Card>
                ):(
                <Row noGap>
                    <Col width="100" medium="60">
                    {this.state.data.waiting &&
                    <Card outline>
                        <CardContent style={{backgroundColor:'#dfe4ea',borderTopLeftRadius:'3px',borderTopRightRadius:'3px'}}>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>Venteliste</span>
                        </CardContent>
                    <CardContent>
                        <p>Deltakeren står på venteliste.</p>
                        <p>
                        Deltaker er på <b>{this.state.data.que}</b> plass i køen.
                        </p>
                    </CardContent>
                    </Card>
                    }
                    {this.state.data.checked_in === 1 &&
                    <Card outline>
                        <CardContent style={{backgroundColor:'#dfe4ea',borderTopLeftRadius:'3px',borderTopRightRadius:'3px'}}>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>Deltaker er registrert.</span>
                        </CardContent>
                        <CardContent>
                        <p>
                        Deltakeren ble registrert for {this.getTimeLeft(this.state.data.checked_in_time)} siden.<br/>({this.formateTimestamp(this.state.data.checked_in_time)})
                        </p>
                        </CardContent>
                    </Card>
                    }

                        {this.state.data.multiple_event_col &&
                        <>
                        <BlockTitle style={{marginTop:"16px"}}>Påmeldingstype</BlockTitle>
                        <Card outline>
                            <CardContent>
                            <p style={{fontSize:"18px",lineHeight:"16px"}}>{this.state.data.event_col.title}</p>
                            {this.state.data.event_col.desc && <div style={{marginTop:'-6px'}} dangerouslySetInnerHTML={{ __html: this.state.data.event_col.desc }}></div>}
                            </CardContent>
                        </Card>
                        </>
                        }
                        <BlockTitle style={{marginTop:"16px"}}>Deltakerinformasjon</BlockTitle>
                        {this.render_participant_data()}

                        {this.state.data.group &&
                        <>
                        <BlockTitle>Gruppepåmelding</BlockTitle>
                        <Card outline style={{backgroundColor:"#dfe4ea"}}>
                            <CardContent>
                            <p>
                            Denne deltakeren er del av en gruppepåmelding.
                            </p>
                            </CardContent>
                        </Card>
                        <Card outline>
                            <CardContent>
                                <List>
                                    <ListItem link={`/${surl}/${eid}/group/${this.state.data.group.parent?this.state.data.signup_data.t_id:this.state.data.group.parent_id}`} title="Gå til gruppen"/>
                                </List>
                            </CardContent>
                        </Card>
                        </>
                        }
                    </Col>
                    <Col width="100" medium="40">
                        {this.render_options()}
                    </Col>
                </Row>
                )}
                </PageBlock>
            )
            }
        </Page>
        }

};