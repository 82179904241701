import ParticipantsPage from './components/pages/participantsPage/participantsPage';

import ParticipantPage from './components/pages/participantPage/participantPage';

import ParticipantsSearchPage from './components/pages/participantsSearchPage/participantsSearchPage';

import ParticipantsTypePage from './components/pages/participantsTypePage/participantsTypePage';

import GroupParticipantPage from './components/pages/groupParticipantPage/main';

import NotFoundPage from './components/pages/NotFoundPage';

export default [
  {
    path: '/:surl/:id',
    component: ParticipantsPage,
  },
  {
    path: '/:surl/:id/search',
    component: ParticipantsSearchPage,
  },
  {
    path: '/:surl/:id/:tid',
    component: ParticipantsTypePage,
  },
  {
    path: '/:surl/:id/participant/:rid',
    component: ParticipantPage,
    options: {
      reloadPrevious: false,
      reloadCurrent: false
    }
  },
  {
    path: '/:surl/:id/group/:rid',
    component: GroupParticipantPage,
    options: {
      reloadPrevious: false,
      reloadCurrent: false
    }
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];
