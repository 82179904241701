import React from 'react';
import {
    f7,
    Page,
    Navbar,
    Preloader,
    Icon,
    List,
    ListItem,
    Card,
    CardContent,
    BlockTitle,
    Row,
    Col,
    Block,
    Link,
} from 'framework7-react';

import NavbarBack from '../../shared/navbar/navbarBack';
import PageBlock from '../../shared/pageBlock/pageBlock';
import {API_URL} from '../../../api';

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
        };
    }

    componentDidMount(){
        this.loadXhr();
    }

    loadXhr(){
        const surl = this.props.f7route.params.surl;
        const eid = this.props.f7route.params.id;
        const rid = this.props.f7route.params.rid;
        fetch(API_URL+'/event/group', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              eid: eid,
              id: rid,
              surl: surl
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            f7.dialog.close();
            if(data.err_code === 0){
                this.setState({
                    loading: false,
                    error: false,
                    data: data,
                    all_event_col: data.all_event_col,
                    participants: data.participants
                });
            }else{
                this.setState({
                    loading:false,
                    error:true,
                    error_mgs: data.err_mgs
                });
            }
        })
        .catch(err => {
            this.setState({
                loading:false,
                error:true,
                error_mgs: 'En ukjent feil har oppstått, prøv igjen senere.'
            });
        })

    }

    renderDataCol(c, n) {
        
        let event_col = this.state.all_event_col.find(x => x.id === n.type);

        if(event_col && event_col.fields.find(x => x.id === c.c_id)){
            let title = event_col.fields.find(x => x.id === c.c_id).title;
            let c_data = event_col.fields.find(x => x.id === c.c_id).data;
            let data = c.data;
        
            if(c.type === 3){
                let arr = c_data;
                if(Array.isArray(arr)){
                data = arr[data];
                }
            }else if(c.type === 4){
        
                let arr = c_data;
                if(Array.isArray(arr)){
                
                    let res = '';
                    
                    JSON.parse(data).forEach((e,i) => {
                        if(e === 1){
                            res = res + `${arr[i]}, `;
                        }
                    });
                    data = res.substring(0, res.length-2);;
                
                }
            }
        
            return <>
            <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>{title}</BlockTitle>
            <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                <ListItem
                    title={data}
                />
            </List>
            </>;
        }
        return false;
    }
    
    render_participants = () => {
        const eid = this.props.f7route.params.id;
        const rid = this.props.f7route.params.rid;
        const surl = this.props.f7route.params.surl;
        return <>
            {this.state.participants && this.state.participants.map( (n, i) => (
            <>
            <BlockTitle>Deltaker {i + 1}</BlockTitle>
            {this.state.all_event_col.length > 1 &&
            <Card outline>
                <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>Påmeldingstype</BlockTitle>
                <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                    <ListItem
                        title={this.state.all_event_col.find(x => x.id === n.type).title}
                    />
                </List>
            </Card>
            }
            <Card outline>
                {n.data.name &&
                <>
                <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>Navn</BlockTitle>
                <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                    <ListItem
                        title={n.data.name}
                    />
                </List>
                </>
                }
                {n.data.data && n.data.data.map( (c) => (
                    this.renderDataCol(c, n)
                ))}
            </Card>
            <Card outline>
                <CardContent>
                    <List>
                        <ListItem link={`/${surl}/${eid}/participant/${n.t_id}`} title={`Gå til deltaker ${i + 1}`}/>
                    </List>
                </CardContent>
            </Card>
            </>
            ))}
        </>
    }


  getTimeLeft(UNIX_timestamp){
    
    UNIX_timestamp = parseInt(UNIX_timestamp);

    var timeNow = Math.floor((new Date().getTime())/1000);
    var d = timeNow - UNIX_timestamp;
    var d_days = Math.floor(d / (24*60*60));
    var d_hours = Math.floor(d / (60*60));
    var d_minutes = Math.floor(d / 60);
    var d_second = Math.floor(d);
    if (d_days >= 2) {
        return d_days + ' dager';
    }
    else if (d_days < 2 && d_days >= 1) {
        return d_days + ' dag';
    }
    else if (d_days < 1 && d_hours >= 2) {
        return d_hours + ' timer ' + (d_minutes-(60*d_hours)) + ' minutter';
    }
    else if (d_hours < 2 && d_hours >= 1) {
        return d_hours + ' time';
    }
    else if (d_hours < 1 && d_minutes >= 2) {
        return d_minutes + ' minutter';
    }
    else if (d_minutes < 2 && d_minutes >= 1) {
        return d_minutes + ' minutt';
    }
    else if (d_minutes < 1 && d_second >= 0) {
        return ' mindre enn 1 minutt';
    }
  }

  formateTimestamp(timestamp) {
    const date = new Date(timestamp*1000);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = "0" + date.getMinutes();
    const month_arr = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
    return day + '. ' + month_arr[month] + '. ' + year + ', kl. ' + hours + ':' + minutes.substr(-2);
  }

  formateTimestampTime(timestamp) {
    const date = new Date(timestamp*1000);
    const hours = "0" + date.getHours();
    const minutes = "0" + date.getMinutes();
    return hours.substr(-2) + ':' + minutes.substr(-2);
  }

  formateTimestampDate(timestamp) {
    const date = new Date(timestamp*1000);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const month_arr = ['jan', 'feb', 'mar', 'apr', 'mai', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'des'];
    return day + '. ' + month_arr[month] + '. ' + year;
  }

  renderQrText(t_id) {
    return t_id.match(/.{1,2}/g).join("-").toUpperCase();
  }

  format_number = (nr) => {
    return nr.toFixed(2).toLocaleString('en').replace(/,/g,' ');
  }


    render() {
        const rid = this.props.f7route.params.rid;
        return <Page>
            <Navbar>
                <NavbarBack title={!this.state.loading && !this.state.error && <>Gruppe - <i>{this.renderQrText(rid)}</i></>}/>
            </Navbar>
            {this.state.loading
            ? (
                <div className="text-align-center" style={{margin:'26px'}}>
                  <Preloader></Preloader>
                </div>
            ) : (
                <PageBlock width="620px">
                {this.state.error ? (
                <Card>
                    <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>{this.state.error_mgs}</span>
                    </CardContent>
                </Card>
                ):(
                <>
                    {(this.state.data.participants[0].data.email || this.state.data.participants[0].data.tel > 0 ) &&
                    <>
                        <BlockTitle style={{marginTop:"16px"}}>Kontaktinformasjon for gruppen</BlockTitle>
                        <Card outline>
                        {this.state.data.participants[0].data.email &&
                        <>
                        <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>E-post</BlockTitle>
                        <List style={{borderBottom:'1px solid rgba(0, 0, 0, .2)'}}>
                            <ListItem
                                title={this.state.data.participants[0].data.email}
                            />
                        </List>
                        </>
                        }
                        {this.state.data.participants[0].data.tel > 0 &&
                        <>
                        <BlockTitle style={{marginTop:'16px',marginBottom:'0'}}>Telefon</BlockTitle>
                        <List>
                            <ListItem
                                title={<><span style={{color:'#666'}}>(+{this.state.data.participants[0].data.tel_prefix})</span> {this.state.data.participants[0].data.tel}</>}
                            />
                        </List>
                        </>
                        }
                        </Card>
                    </>
                    }
                    {this.render_participants()}
                </>
                )}
                </PageBlock>
            )
            }
        </Page>
        }

};